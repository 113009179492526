<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div class="container checkout--container extra--padding">
      <page-loading :show="loading" />
      <div class="left--col-container">
        <div class="checkout--content">
          <product-info />
          <payment-method
            v-if="checkoutInfo && checkoutInfo.total_net_price > 0"
            @submit="submit()"
          />
          <promo-section />
        </div>
      </div>
      <div class="right--col-container">
        <checkout-info @submit="submit()" />
        <checkout-terms></checkout-terms>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import PageLoading from '@/components/content-loading/page-loading';
import ProductInfo from '@/components/checkout/product-info';
import PaymentMethod from '@/components/checkout/payment-method';
import CheckoutInfo from '@/components/checkout/checkout-info';
import PromoSection from '@/components/checkout/promo-section';
import CheckoutTerms from '@/components/checkout/checkout-terms';
import { mapState } from 'vuex';
import { decryptToken } from '@/utils/helpers';

export default {
  name: 'index',
  head: {
    script: [
      {
        src:
          process.env.NODE_ENV !== 'production'
            ? 'https://app.sandbox.midtrans.com/snap/snap.js'
            : 'https://app.midtrans.com/snap/snap.js',
        'data-client-key':
          process.env.NODE_ENV !== 'production'
            ? 'SB-Mid-client-xGrFbeIAyF57UyPC'
            : 'Mid-client-yJe5bOStNIlZBnkR',
      },
    ],
  },
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    PageLoading,
    ProductInfo,
    PaymentMethod,
    CheckoutInfo,
    PromoSection,
    CheckoutTerms,
  },
  computed: {
    ...mapState({
      loading: (state) => state.checkout.pay.loading,
      paymentMethod: (state) => state.checkout.pay.paymentMethod,
      checkoutInfo: (state) => state.checkout.checkoutInfo,
    }),
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.checkout');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  async mounted() {
    let token = this.$store.state.global.token;
    let newToken = this.$route.query.token;
    if (newToken && token !== newToken) {
      token = decryptToken(newToken);
      await this.$store.dispatch('global/updateToken', token);
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
    let productUuid = this.$route.query.product;
    if (productUuid != null) {
      await this.$store.dispatch('checkout/getCheckoutInfo', {
        product: {
          uuid: productUuid,
        },
        token: token,
      });
    }
  },
  methods: {
    async submit() {
      try {
        const data = await this.$store.dispatch('checkout/pay/submit', {
          totalNetPrice: this.checkoutInfo.total_net_price,
          directActivation: this.$route.query ? this.$route.query.direct_activation : null,
          duration: this.checkoutInfo.duration,
        });
        if (data.payment === true) {
          if (this.paymentMethod == 'credit_card') {
            console.log('Open midtrans: ', data);
            // let self = this;
            // self.$modal.show('modal-midtrans-overlay');
            // eslint-disable-next-line no-undef
            snap.pay(data.token, {
              // eslint-disable-next-line no-unused-vars
              onSuccess: (result) => {
                console.log('on success');

                // self.$modal.hide('modal-midtrans-overlay');
                // self.$router.push('/dashboard/mybooking');
                this.$router.replace(`/order?q=${data.order_number}`);
              },
              // eslint-disable-next-line no-unused-vars
              onPending: (result) => {
                console.log('on pending');
                // self.$modal.hide('modal-midtrans-overlay');
                this.$router.replace(`/how-to-pay/${data.uuid}`);
              },
              onError: (result) => {
                console.log('on error midtrans: ', result);
                // this.$router.replace(`/order?q=${data.order_number}`);
              },
              onClose: () => {
                // console.log('customer closed the popup without finishing the payment');
                // this.$router.replace(`/order?q=${data.order_number}`);
              },
            });
          } else {
            if (data.order_uuid) {
              this.$router.replace(`/how-to-pay/${data.order_uuid}`);
            } else {
              this.$swal(this.$i18n.t('booking.failedPayment'), data.message, 'error');
            }
          }
        } else if (data.payment === false) {
          this.$router.replace(`/order?q=${data.order_number}`);
        }
      } catch (e) {
        console.log('CHECKOUT SUBMIT ERROR: ', e);
      }
    },
  },
};
</script>

<template>
  <div class="checkout--terms mt-4">
    <div>
      {{ $t('booking.paymentAlert') }}
      <a target="_blank" :href="`${userWebsiteUrl}/syarat-ketentuan`"
        >{{ $t('booking.termsCondition') }}&nbsp;</a
      >{{ $t('general.and') }}
      <a target="_blank" :href="`${userWebsiteUrl}/kebijakan-privasi`"
        >{{ $t('booking.privacyPolicy') }}
      </a>
      {{ $t('booking.beliruma') }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    userWebsiteUrl() {
      return process.env.VUE_APP_USER_URL;
    },
  },
};
</script>

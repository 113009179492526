<template>
  <v-card
    class="card--payment-method"
    :class="{
      selected: selectedPaymentMethod === paymentMethod,
    }"
    v-if="selectedPaymentMethod"
    @click="selectPaymentMethod"
  >
    <img
      src="@/assets/img/icons/payments/permata.png"
      :alt="$t('booking.permataVA')"
      style="padding: 8px"
      v-if="paymentMethod === 'permata_va'"
    />
    <img
      src="@/assets/img/icons/payments/bni.svg"
      :alt="$t('booking.bniVA')"
      style="padding: 12px"
      v-if="paymentMethod === 'bni_va'"
    />
    <img
      src="@/assets/img/icons/payments/bri.svg"
      :alt="$t('booking.briVA')"
      style="padding: 12px"
      v-if="paymentMethod === 'bri_va'"
    />
    <img
      src="@/assets/img/icons/payments/gopay.png"
      :alt="'GoPay'"
      v-if="paymentMethod === 'gopay'"
    />
    <img
      src="@/assets/img/icons/payments/credit-card.png"
      :alt="$t('booking.creditCardMidtrans')"
      v-if="paymentMethod === 'credit_card'"
    />
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    paymentMethod: {
      default: null,
      require: true,
    },
  },
  data: () => ({}),
  computed: {
    selectedPaymentMethod: {
      get() {
        return this.$store.state.checkout.pay.paymentMethod;
      },
      set(val) {
        this.$store.commit('checkout/pay/SET_PAYMENT_METHOD', val);
      },
    },
  },
  methods: {
    selectPaymentMethod() {
      this.selectedPaymentMethod = this.paymentMethod;
    },
  },
};
</script>

<template>
  <div class="request--book-promo">
    <section-loading :show="isLoading" />
    <div class="left--part">
      <div class="title--text">
        {{ $t('general.promo.title') }}
      </div>
    </div>
    <div class="right--part">
      <div class="d-flex">
        <div style="position: relative">
          <input class="basic--input promo--input" v-model="promoCode" />
          <div class="delete--btn" @click="deletePromoCode" v-show="promoCode">
            <i class="ion-close"></i>
          </div>
        </div>
        <button class="btn btn-primary ml-4 check--code-btn" @click="checkPromoCode">
          {{ $t('general.promo.checkCode') }}
        </button>
      </div>
      <div class="valid--text" v-if="valid">{{ $t('general.promo.valid') }}</div>
      <div class="error--text" v-else-if="errors">
        <div>{{ errors }}</div>
        <ul v-if="requirements && requirements.length > 0">
          <li v-for="(req, index) in requirements" :key="`requirement-${index}`">
            {{ req }}
          </li>
        </ul>
      </div>
      <!--      <div class="mt-4" v-if="chosenPromo">-->
      <!--        <span v-show="!promoWarning">{{ chosenPromo.title }}</span>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';
const SectionLoading = () => import('@/components/content-loading/section-loading');

export default {
  name: 'promo-section',
  mixins: [LodashMixin],
  components: {
    SectionLoading,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.checkout.promo.isLoading,
      products: (state) => state.checkout.pay.products,
      valid: (state) => state.checkout.promo.valid,
      errors: (state) => state.checkout.promo.errors,
      requirements: (state) => state.checkout.promo.requirements,
      checkoutInfo: (state) => state.checkout.checkoutInfo,
    }),
    promoCode: {
      get() {
        return this.$store.state.checkout.promo.promoCode;
      },
      set(val) {
        this.$store.commit('checkout/promo/SET_PROMO_CODE', val);
      },
    },
  },
  async created() {
    // this.debouncedPromo = this.debounce(this.checkPromoCode, 250);
    await this.$store.dispatch('checkout/promo/restoreInitialState');
  },
  methods: {
    async deletePromoCode() {
      await this.$store.dispatch('checkout/promo/restoreInitialState');
    },
    async checkPromoCode() {
      if (!this.isLoading) {
        await this.$store.dispatch('checkout/promo/checkPromo', {
          promoCode: this.promoCode,
          productUuid: this.products[0].uuid,
          directActivation: this.$route.query ? this.$route.query.direct_activation : null,
          qty: this.products[0].qty,
          duration: this.checkoutInfo.duration,
        });
      }
    },
  },
  watch: {
    // promoCode(val) {
    //   this.debouncedPromo(val);
    // },
  },
};
</script>

<style scoped></style>

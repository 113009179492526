<template>
  <div>
    <div v-if="show" class="page--loading d-flex justify-center align-center">
      <div>
        <div class="loading--img-wrapper">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/logo/icon.svg"
            width="50"
            alt="Loading"
            class="loading--img"
          />
          <v-progress-circular :size="150" indeterminate></v-progress-circular>
        </div>
        <h5 class="text-center loading--text mt-4">Loading...</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show'],
};
</script>

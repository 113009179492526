<template>
  <div class="label--container">
    <div class="label mr-2" v-if="membership">
      {{ `${$t('product.membership')} ${membership}` }}
    </div>
    <div class="label mr-2" v-if="listingSlot">
      {{ `${$t('product.listingSlot')} ${listingSlot}` }}
    </div>
    <div class="label mr-2" v-if="coinSlot">{{ `${$t('product.coinSlot')} ${coinSlot}` }}</div>
    <div class="d-flex align-center flex-wrap" v-if="benefits">
      <div class="label mr-2" v-for="(benefit, index) in benefits" :key="`benefit-${index}`">
        {{ benefit }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['membership', 'listingSlot', 'coinSlot', 'benefits'],
  computed: {},
  methods: {},
};
</script>

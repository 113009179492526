<template>
  <div v-if="checkoutInfo && checkoutInfo.items && checkoutInfo.items.length > 0">
    <div
      class="product--info"
      v-for="(product, index) in checkoutInfo.items"
      :key="`checkout-info-${index}`"
    >
      <div class="left--part">
        <div class="top--part">
          <div>
            <div class="title--text">
              <span class="mr-2">{{ product.name }}</span>
              <img :src="product.membership_logo" v-if="product.membership_logo" />
            </div>
            <strong>
              <span>{{ $t('general.price') }}:</span>
              <span class="color--primary" v-if="product.discount_sum && product.discount_sum > 0">
                <span class="ml-2 mr-2 price--before"
                  >{{ $n(product.price_sum, 'currency', 'id-ID') }}
                </span>
                <span>{{ $n(product.price_sum - product.discount_sum, 'currency', 'id-ID') }}</span>
              </span>
              <span class="color--primary" v-else>
                {{ $n(product.price_sum, 'currency', 'id-ID') }}
              </span>
            </strong>
            <div class="active--date" v-if="product.active_at">
              {{ $t('product.activeAt') }}: {{ product.active_at }}
            </div>
          </div>
          <div class="quantity">
            <div>
              <div class="title--text">{{ $t('checkout.quantity') }}</div>
              <div class="content">{{ product.qty }}</div>
            </div>
          </div>
        </div>
        <div class="bottom--part">
          <product-labels :benefits="product.benefits"></product-labels>
        </div>
      </div>
      <div class="right--part">
        <div class="quantity">
          <div>
            <div class="title--text">{{ $t('checkout.quantity') }}</div>
            <div class="content">{{ product.qty }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductLabels from '@/components/utils/product-labels';
import { mapState } from 'vuex';

export default {
  components: {
    ProductLabels,
  },
  computed: {
    ...mapState({
      checkoutInfo: (state) => state.checkout.checkoutInfo,
    }),
  },
};
</script>
